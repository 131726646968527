<template>
  <div class="grid w-full grid-cols-1 gap-4 xl:grid-cols-[2fr_1fr] 2xl:grid-cols-[3fr_2fr] overflow-scroll h-screen sm:p-8 xl:overflow-hidden">
    <div class="flex h-full flex-col rounded-3xl xl:shadow-md p-8 xl:overflow-y-scroll xl:bg-white">
      <slot name="leftCol" />
    </div>
    <div class="hidden xl:flex h-full flex-col rounded-3xl bg-white p-6 shadow-md sm:p-8 overflow-y-scroll">
      <slot name="rightCol" />
    </div>
  </div>
</template>
